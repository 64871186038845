<template>
  <div class="bg">
    <div class="container">
      <h1>PERK - Обратная связь менеджера</h1>
      <v-alert
          v-if="popup === true"
          :color="classPopUp"
          :icon="'$' + classPopUp"
          :text="textPopUp"
      ></v-alert>
      <form @submit.prevent="formSubmit" v-if="popup !== true">

        <label for="selectedGrade">Кол-во сотрудников согласно плану (если не в плане, причины)</label>
        <v-textarea
            id="employees"
            variant="outlined"
            v-model="employees"
            rounded="rounded-xl"
        ></v-textarea>

        <label for="selectedGrade">Кто опоздал и на сколько?</label>
        <v-textarea
            id="beLate"
            variant="outlined"
            v-model="beLate"
            rounded="rounded-xl"
        ></v-textarea>

        <label for="selectedGrade">Форс мажоры / проблемы</label>
        <v-textarea
            id="troubles"
            variant="outlined"
            v-model="troubles"
            rounded="rounded-xl"
        ></v-textarea>

        <label for="lag">Задержки (были/не были - цеха)</label>
        <v-textarea
            id="lag"
            variant="outlined"
            v-model="lag"
            rounded="rounded-xl"
        ></v-textarea>

        <label for="selectedGrade">Стопы (были/не были - позиции)</label>
        <v-textarea
            id="stops"
            variant="outlined"
            v-model="stops"
            rounded="rounded-xl"
        ></v-textarea>

        <label for="selectedGrade">Эффективность ФОТ (кого отпустили и во сколько)</label>
        <v-textarea
            id="efficiency"
            variant="outlined"
            v-model="efficiency"
            rounded="rounded-xl"
        ></v-textarea>

        <label for="suggestions">Предложения по улучшению</label>
        <v-textarea
            id="selectedCommentDish"
            variant="outlined"
            v-model="suggestions"
            rounded="rounded-xl"
        ></v-textarea>

        <v-btn
            variant="tonal"
            type="submit"
            size="large"
            :disabled="
            this.formState === 'send'
              ? true
              : sendDisabled
          "
            class="btn"
        >
          Отправить
        </v-btn>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      formState: "wait",
      employees: "",
      beLate: "",
      troubles: "",
      lag: "",
      stops: "",
      efficiency: "",
      suggestions: "",
      popup: false,
      textPopUp: "",
      classPopUp: "",
      file: null,
    };
  },
  computed: {
    sendDisabled() {
      return this.employees.length < 1
          || this.beLate.length < 1
          || this.troubles.length < 1
          || this.lag.length < 1
          || this.stops.length < 1
          || this.efficiency.length < 1
          || this.suggestions.length < 1;
    }
  },
  methods: {
    async formSubmit() {
      this.formState = "send";

      let message = `<b>Новый отзыв!</b>\n\n`;
      message += `Кол-во сотрудников согласно плану (если не в плане, причины):\n <b>${this.employees}</b>\n\n`;
      message += `Кто опоздал и на сколько?:\n <b>${this.beLate}</b>\n\n`;
      message += `Форс мажоры / проблемы:\n <b>${this.troubles}</b>\n\n`;
      message += `Задержки (были/не были - цеха):\n <b>${this.lag}</b>\n\n`;
      message += `Стопы (были/не были - позиции):\n <b>${this.stops}</b>\n\n`;
      message += `Эффективность ФОТ (кого отпустили и во сколько):\n <b>${this.efficiency}</b>\n\n`;
      message += `Предложения по улучшению:\n <b>${this.suggestions}</b>\n\n`;
      message += `Дата:\n <b>${new Date().toLocaleString().replace(",", " -")}</b>\n\n`;


      await axios
          .post(process.env.VUE_APP_URL_TEXT_TELEGRAM, {
            chat_id: process.env.VUE_APP_CHAT_PERK_6_ID_MANAGER,
            parse_mode: "html",
            text: message,
          })
          .then((res) => {
            this.textPopUp = "Ваша заявка успешно отправлена!";
            this.classPopUp = "success";
          })
          .catch((err) => {
            this.textPopUp = "Произошла какая-то ошибка! Попробуйте еще раз!";
            this.classPopUp = "error";
          });

      const formData = new FormData();

      formData.append(
          "date",
          `${new Date().toLocaleString().replace(",", " -")}`
      );
      formData.append("employees", this.employees);
      formData.append("beLate", this.beLate);
      formData.append("troubles", this.troubles);
      formData.append("lag", this.lag);
      formData.append("stops", this.stops);
      formData.append("efficiency", this.efficiency);
      formData.append("suggestions", this.suggestions);

      await axios
          .post(process.env.VUE_APP_GSHEETS_PERK_6_MANAGER, formData)
          .then((result) => {
            console.log("Данные успешно отправлены в таблицу:", result);
          })
          .catch((error) => {
            console.error("Ошибка при отправке данных в таблицу:", error);
          });
      this.popup = true;

      this.formState = "wait";
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,700&display=swap");
.v-btn {
  display: flex;
  border: 0px;
  border-radius: 0px;
  background-color: #c8b9a0;
  font-family: "Roboto";
  font-size: 22px;
  font-weight: 300;
  color: #41464b;
}

.v-btn--disabled {
  background-color: grey;
  color: #41464b;
}

.v-btn.v-btn--density-default {
  height: 60px;
  width: 200px;
}
.btn {
  position: relative;
  margin: 0 auto;
}

.v-slider.v-input {
  margin-top: 30px;
}

#selectedDish[data-v-e50ac4b0] > .v-input__control > .v-field {
  border-radius: 50px !important;
}

h1 {
  font-family: "Roboto";
  font-weight: 700;
  /* text-align: center; */
  text-transform: uppercase;
  font-size: 40px;
}

label {
  font-family: "Roboto";
  font-size: 30px;
  font-weight: 400;
  color: #000;
}
form:deep(.v-field) {
  border-radius: 0px;
}

@media (max-width: 1000px) {
  h1 {
    font-size: 30px;
  }

  label {
    font-size: 20px;
  }

  .v-btn {
    font-size: 16px;
    width: 100%;
  }

  .v-btn.v-btn--density-default {
    height: 40px;
    width: 160px;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 24px;
  }

  label {
    font-size: 20px;
  }

  .v-btn {
    width: 100% !important;
  }
}
</style>
